<template>
  <div>
    <h2
      class="u-color-grey-base u-font-size-5 u-text-case-upper u-spacing-mb-s u-font-weight-600 u-spacing-ml-l"
    >
      {{ config.heading }}
    </h2>
    <div
      class="u-color-grey-lighter u-font-size-7 u-spacing-mb-l u-spacing-ml-l"
    >
      {{ config['sub-heading'] }}
    </div>
    <div
      v-if="config.message"
      class="u-color-grey-base u-font-size-5 u-font-weight-400 u-spacing-mv-l u-spacing-ml-l"
    >
      {{ config.message }}
    </div>
    <component
      :is="config.customComponent.ui_component"
      v-if="config.customComponent"
      v-bind="config.customComponent.props"
    />

    <footer
      v-if="!disableFooter"
      slot="footer"
      class="u-display-flex u-flex-align-items-center u-spacing-pv-m u-spacing-mt-m u-spacing-ph-l u-border-width-s u-border-top u-border-bottom u-border-color-grey-xxx-light"
    >
      <rb-button
        :click-fn="config.apply"
        :text="config.leftBtnLabel || 'Apply'"
        :size="'s'"
        :type="'filled'"
        class="u-spacing-mr-m u-cursor-pointer"
      />
      <rb-button
        :click-fn="config.handleCancel"
        :text="config.rightBtnLabel || 'Cancel'"
        :size="'s'"
        :type="'hollow'"
        class="u-color-grey-light u-cursor-pointer"
      />
    </footer>
  </div>
</template>

<script>
export default {
  name: 'ApproveRec',
  props: {
    config: {
      type: Object,
      default() {
        return {};
      }
    },
    disableFooter: {
      type: Boolean,
      default: false
    }
  }
};
</script>
